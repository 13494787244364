<template>
  <div class="wallet-favorite-send">
    <div class="container-fluid mt--5">
      <div class="card mb-3">
        <div class="card-header">
          <h3 class="mb-0">
            <i class="el-icon-star-on" style="color: #e6a23c"></i>
            {{ favoriteName ? favoriteName : "Favorite Payment" }}
          </h3>
        </div>
        <div v-if="allowedTrade == 'no'" class="card-body">
          <div class="mb-4">
            <div class="card p-2 mt-2 mb-3 alert-danger">
              <div class="text-center">
                <span>Your account can't use this function</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="allowedTrade != 'no'"
          class="card-body"
          v-loading="loading"
          style="min-height: 200px"
        >
          <div class="row mb-6" v-if="kycStatus && kycStatus != 'approve'">
            <el-alert
              :title="`KYC Status ${kycStatus}`"
              type="error"
              :description="'Please finish your KYC on AB app'"
              :closable="false"
            ></el-alert>
          </div>
          <el-form
            v-if="kycStatus && kycStatus == 'approve'"
            ref="formData"
            :model="formData"
            :rules="formRules"
          >
            <div v-if="rateofftime" class="row justify-content-md-center">
              <div class="col-12 col-lg-8 col-xl-4 mb-4">
                <el-alert :title="rateofftime_message" type="error" :closable="false"></el-alert>
              </div>
            </div>
            <div class="row justify-content-md-center" v-if="Object.keys(favoriteData).length > 0">
              <div class="col-12 col-lg-6 col-xl-4">
                <div class="card p-2 mb-3 pb-4 card-wallet">
                  <div class="card-header p-1 border-0">
                    <h4 class="mb-0">From</h4>
                  </div>
                  <div class="wallet-item-select" style="width: 100%">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        <el-image
                          style="width: 48px"
                          :src="`/img/currency-flags/${favoriteData.bankInfo.currency.toLowerCase()}.png`"
                          fit="contain"
                        ></el-image>
                        <div style="margin-left: 8px">
                          <div v-if="upgradePlus" class="account-number">{{ favoriteData.bankInfo.cardholderid }}</div>
                          <div class="name">My {{ favoriteData.bankInfo.currency }} Account</div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="text-right">
                          <div class="p-0 text-xs" style="line-height: 10px">Balance</div>
                          <div class="p-0" style="line-height: 20px">
                            <span class="font-weight-bold">
                              {{
                              favoriteData.balance
                              }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 col-xl-4">
                <div class="card p-2 mb-3 card-wallet">
                  <div class="card-header p-1 border-0">
                    <h4 class="mb-0">To</h4>
                  </div>
                  <div class="payee-item-select" style="width: 100%">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-start">
                        <el-image
                          style="width: 48px"
                          :src="`/img/currency-flags/${favoriteData.payee.currency.toLowerCase()}.png`"
                          fit="contain"
                        ></el-image>
                        <div style="margin-left: 8px">
                          <div class="account-number">{{ favoriteData.payee.accountNumber }}</div>
                          <div class="bank-name">{{ favoriteData.payee.bankName }}</div>
                          <div class="name">{{ favoriteData.payee.payeeName }}</div>
                        </div>
                      </div>
                      <div class="d-flex align-items-center">
                        <div class="text-right">
                          <div class="p-0 font-weight-bold">{{ favoriteData.payee.currency }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-8 col-xl-6">
                <div class="card p-2 mb-3">
                  <div class="card-header p-1 border-0">
                    <h4 class="mb-0">
                      Amount
                      {{
                      paymentType == "sell"
                      ? wallet_from_currency
                      : wallet_to_currency
                      }}
                    </h4>
                  </div>
                  <div class="mt-2">
                    <div class="text-xs">You want to Sell or Buy?</div>
                    <el-form-item label>
                      <el-radio-group v-model="paymentType" @change="amountEnter()">
                        <el-radio label="sell" border size="medium">Sell</el-radio>
                        <el-radio label="buy" border size="medium">Buy</el-radio>
                      </el-radio-group>
                    </el-form-item>

                    <free-trade-list @onChanged="onSelectFreeTrade"></free-trade-list>
                    <el-form-item label prop="amount">
                      <el-input
                        class="big-input"
                        type="number"
                        placeholder="Please enter amount"
                        v-model="formData.amount"
                        @input="amountEnter()"
                      ></el-input>
                    </el-form-item>

                    <div>
                      <QuoteButton
                        ref="quotebtn"
                        :customertype="'normal'"
                        :from="wallet_from_currency"
                        :to="wallet_to_currency"
                        :amount="formData.amount"
                        :fx="false"
                        :paymenttype="paymentType"
                        :freeuid = "freeuid"
                        @onQuoted="onQuoted"
                        @onTimeout="onTimeout"
                      />
                    </div>

                    <el-form-item label="Purpose of payment">
                      <el-input :value="getPurpose()" readonly />
                    </el-form-item>

                    <el-form-item label="Reference" prop="reference">
                      <el-input v-model="formData.reference" v-input-filter placeholder="Reference"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>

              <div class="col-12 text-center" v-if="quoted">
                <el-button
                  type="primary"
                  round
                  :loading="submitting"
                  :disabled="submitting"
                  @click="onSubmit"
                >Confirm</el-button>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <WalletReceipt ref="WalletReceipt" @onClose="onCloseReceipt" />
    <ReviewRateDialog ref="reviewratedialog" @onClose="gotoHistory"/>
  </div>
</template>

<script>
import FavoriteApi from "@/api/wallet/favorite";
import QuoteButton from "@/views/Wallet/components/QuoteButton.vue";
import WalletPayment from "@/api/wallet/payment";
import WalletReceipt from "@/views/Wallet/components/WalletReceipt";
import AccountApi from "@/api/wallet/account";
import FreeTradeList from '@/components/FreeTrade/FreeTradeList.vue';
import ReviewRateDialog from '@/components/Review/Review'
export default {
  components: {
    QuoteButton,
    WalletReceipt,
    FreeTradeList,
    ReviewRateDialog
  },
  computed: {
    allowedTrade() {
      return this.$store.getters.allowedtrade;
    },
    upgradePlus() {
      return this.$store.getters.feature ? this.$store.getters.feature.upgrade : false;
    },
    reviewState() {
      return this.$store.getters.reviewstate;
    },
  },
  data() {
    return {
      loading: false,
      favoriteData: {},
      favoriteName: "",
      favoriteUID: this.$route.params.uid,
      submitting: false,
      wallet_from_currency: "",
      wallet_to_currency: "",
      quoted: false,
      rateofftime: false,
      rateofftime_message: "",
      paymentType: "sell",
      kycStatus: "",
      freeuid:"",
      paymentReceipt: {},
      formData: {
        from_bankholderid: "",
        currency_from: "",
        payee_uid: "",
        amount: "",
        fee: "",
        rate: "",
        reference: "",
        exchangerate_type: "normal",
        use_fasttrack: false,
        purpose: "",
        purposeOther: "",
        idempotency: "",
        verifytoken: "",
        qoutetoken: "",
        calresponse: {},
      },
      formRules: {
        amount: [
          {
            required: true,
            message: "Please enter Amount",
            trigger: "blur",
          },
        ],
        reference: [
          {
            required: true,
            message: "Please enter reference",
            trigger: "blur",
          },
          {
            message: " Reference must put 6-18 character ",
            min: 6,
            max: 18,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    getKyc() {
      AccountApi.getKycState().then(({ result, data }) => {
        if (result) {
          this.kycStatus = data["status"];
        }
      });
    },
    paymentOfftime() {
      this.loading = true;
      WalletPayment.paymentOfftime().then(({ result, data, message }) => {
        this.loading = false;
        if (result) {
          this.rateofftime = data["offtime"];
          this.rateofftime_message = data["message"];
        }
      });
    },
    getFavorite(uid) {
      this.loading = uid;
      FavoriteApi.getFavoriteByUid(uid).then(
        ({ result, data }) => {
          this.loading = false;
          if (result) {
            this.favoriteData = data;
            this.favoriteName = data.favoriteName;
            this.formData.from_bankholderid = data.bankInfo.cardholderid;
            this.formData.currency_from = data.bankInfo.currency;
            this.formData.payee_uid = data.payee.payeeUID;
            this.formData.purpose = data.purpose;
            this.formData.purposeOther = data.purposeOther;
            this.formData.reference = data.payee.reference;
            this.wallet_from_currency = data.bankInfo.currency;
            this.wallet_to_currency = data.payee.currency;
            this.$emit("changeFrom", this.wallet_from_currency);
            this.$emit("changeTo", this.wallet_to_currency);
          }
        },
        (err) => {
          this.loading = false;
          if (err.response) {
            this.$swal("Message", err.response.data.message, "error");
          }
        }
      );
    },

    amountEnter() {
      this.$refs.quotebtn.clearTimer();
    },
    getPurpose() {
      return this.formData.purposeOther
        ? this.formData.purposeOther
        : this.formData.purpose;
    },
    onQuoted(data) {
      this.formData.verifytoken = data["verifytoken"];
      this.formData.qoutetoken = data["qoutetoken"];
      this.formData.calresponse = data["response"];
      this.formData.rate = parseFloat(this.formData.calresponse.rate);
      this.formData.fee = parseFloat(this.formData.calresponse.fee);
      this.formData.use_fasttrack = data.fasttrack;
      this.quoted = true;
    },
    onSelectFreeTrade(freeuid){
      this.amountEnter();
      this.freeuid = freeuid;
    },
    onTimeout() {
      this.quoted = false;
    },

    onSubmit() {
      this.$refs.formData.validate((valid) => {
        if (valid) {
          this.submitting = true;
          let model = JSON.parse(JSON.stringify(this.formData));
          model.amount = parseFloat(this.formData.amount);
          WalletPayment.paymentWallet(model).then(
            ({ result, data, message }) => {
              this.submitting = false;
              this.amountEnter();
              if (result) {
                this.paymentReceipt = data["receipt"];
                this.$refs.WalletReceipt.open(
                  this.paymentReceipt["payment_UID"],
                  "",
                  this.formData.from_bankholderid
                );
                
              } else {
                this.$swal("Message", message, "error");
              }
            },
            (err) => {
              this.submitting = false;
              this.amountEnter();
              if (err.response) {
                this.$swal("Message", err.response.data.message, "error");
              }
            }
          );
        }
      });
    },
    onCloseReceipt() {
      if (this.reviewState){
       this.gotoHistory()
      }else{
        this.showReview();
      }
    },
    gotoHistory(){
      this.$router.push({
        path: "/wallet/history",
      });
    },
    showReview(){
      this.$refs.reviewratedialog.show('autofx', this.paymentReceipt)
    }
  },
  mounted() {
    this.getKyc();
    this.paymentOfftime();
    this.getFavorite(this.favoriteUID);
  },
};
</script>

<style lang="scss">
.wallet-favorite-send {
  .account-number {
    font-size: 1rem;
    font-weight: 700;
  }

  .name,
  .bank-name {
    font-size: 0.75rem;
  }
}
</style>
